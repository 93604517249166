import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    // backgroundImage: "url(./images/footerbg.png)",
    borderTop: "1px solid #423F3F",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
    },
    "& ul": {
      padding: "0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "400",
      color: "#ffffff",
      // marginTop: "20px",
      // marginBottom: "20px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },

  footerTitle: {
    "&:hover": {
      color: " #FF632C",
    },
  },

  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  button: {
    backgroundColor: "#fab758",
    color: "#ffffff",
    border: "2px solid #fab758",
    borderRadius: "40px",
    minHeight: "50px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: "500",
    transition: "0.26s ease",
    minWidth: "160px",
    paddingBottom: "3px",
    textTransform: "capitalize",
    marginRight: "15px",
    marginBottom: "10px",
    marginTop: "27px",
  },
  join: {
    color: "#ffffff",
    fontSize: "30px",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#3F78E0",
      minWidth: "90px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  var year3 = new Date().getFullYear();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container style={{ color: "white" }} spacing={1}>
            <Grid item xs={12} sm={4} md={4}>
              <Grid
                container
                direction="column"
                spacing={9}
                alignItems="flex-start"
              >
                <Grid item>
                  {" "}
                  {/*{/ <Logo width="100" />{" "} /}*/}
                  <img
                    src="/images/logo.png"
                    alt=""
                    width="100%"
                    style={{ maxWidth: "300px", margin: "0 auto" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography className={classes.footerTitle} variant="h3">
                    SERVICES
                  </Typography>
                  <List className="listingFooter">
                    <ListItem
                      // style={{ color: "#ffffff" }}
                      to="/create-presale"
                      component={Link}
                    >
                      Create Launchpad
                    </ListItem>

                    <ListItem
                      // style={{ color: "#ffffff" }}
                      to="/our-team"
                      component={Link}
                    >
                      Our Team
                    </ListItem>
                  </List>{" "}
                </Grid>

                <Grid item xs={6} sm={4} md={4}>
                  <Typography className={classes.footerTitle} variant="h3">
                    LEARN MORE
                  </Typography>
                  <List className="listingFooter">
                    <ListItem
                      to="/roadmap"
                      component={Link}
                      // style={{ color: "#ffffff" }}
                    >
                      Roadmap
                    </ListItem>
                  </List>{" "}
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography className={classes.footerTitle} variant="h3">
                    SOCIAL
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {" "}
                    <List className="listingFooter">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open("https://twitter.com/wittyclub")
                        }
                      >
                        {" "}
                        <TwitterIcon />
                      </IconButton>
                    </List>
                    <List className="listingFooter">
                      <IconButton
                        size="small"
                        onClick={() => window.open("https://t.me/Wittytech")}
                      >
                        {" "}
                        <TelegramIcon />
                      </IconButton>
                    </List>
                    <List className="listingFooter">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UC-ppfX5lzWgSMFdclwxKcIw"
                          )
                        }
                      >
                        {" "}
                        <YouTubeIcon />
                      </IconButton>
                    </List>
                    <List className="listingFooter">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open("https://instagram.com/wittyclubofficial")
                        }
                      >
                        {" "}
                        <InstagramIcon />
                      </IconButton>
                    </List>
                    <List className="listingFooter">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open("https://facebook.com/officialwitty ")
                        }
                      >
                        {" "}
                        <FacebookIcon />
                      </IconButton>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item={4} sm={4} xs={12}>
                <Box>
                  <p
                    style={{
                      color: "#969595",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    {`Copyright © ${year3} . All rights reserved.`}
                  </p>
                </Box>
              </Grid>
              <Grid item={8} sm={8} xs={12} style={{ textAlign: "end" }}>
                <Box>
                  <p
                    style={{
                      color: "#969595",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                    }}
                  >
                    <Link
                      to="/terms-of-services"
                      style={{ textDecoration: "none", color: "#969595" }}
                    >
                      Terms of service&nbsp;
                    </Link>
                    |{" "}
                    <Link
                      to="/privacy-policy"
                      style={{ textDecoration: "none", color: "#969595" }}
                    >
                      Privacy policy
                    </Link>{" "}
                    |{" "}
                    <Link
                      to="/faq"
                      style={{ textDecoration: "none", color: "#969595" }}
                    >
                      Help Center
                    </Link>{" "}
                    |{" "}
                    <a
                      href="mailto:support@info.com"
                      style={{ color: "#969595", textDecoration: "none" }}
                    >
                      support@info.com
                    </a>
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
