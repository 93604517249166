import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { getContract, getWeb3Obj } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import FactoryABI from "src/constants/FactoryABI.json";
import { factoryAddress } from "src/constants";
import { toast } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";

export default function WhiteListAddress({
  data,
  isRemoving,
  removeFromWhitelistHandlers,
  setUpdateMinSatkeOpen1,
}) {
  const { account, chainId, library } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [userFee, setUserFee] = useState("");
  const [adminFee, setAdminFee] = useState("");
  const [maxHardCap, setMaxHardCap] = useState("");
  const [isUpdatingFee, setIsupdatingFee] = useState(false);
  const [feeBtnName, setBtnName] = useState("Update Details");

  const setFeehandler = async () => {
    const web3 = await getWeb3Obj();
    try {
      setBtnName("Updating...");
      setIsupdatingFee(true);
      const contractObj = getContract(
        factoryAddress,
        FactoryABI,
        library,
        account
      );

      const setFee = await contractObj.setUserDetails(
        data?.address,
        (adminFee * 100).toString(),
        (userFee * 100).toString(),
        web3.utils.toWei(maxHardCap)
      );
      await setFee.wait();
      setIsupdatingFee(false);
      setBtnName("Update Details");
      toast.success("Details has been updated successfully");
      setUpdateMinSatkeOpen1(false);
      setOpen(false);
    } catch (err) {
      console.log(err);
      setIsupdatingFee(false);
      setBtnName("Update Details");
      setOpen(false);
      toast.error(err.message);
    }
  };
  return (
    <>
      <TableRow style={{ width: "100%" }}>
        <TableCell>{data?.address ? data?.address : "0x000"}</TableCell>
        <TableCell>
          <Box
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   width: "100%",
            // }}
          >
            <Button
              variant="outlined"
              style={{ background: "#FF632C" }}
              onClick={() => removeFromWhitelistHandlers(data?.address)}
              disabled={isRemoving}
            >
              Remove
            </Button>
            <Button
              variant="outlined"
              style={{ background: "#FF632C", marginLeft: "12px" }}
              onClick={() => {
                setOpen(true);
              }}
              disabled={isRemoving}
              // disabled={isRemoving}
            >
              Set Details
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        // disableBackdropClick={isUpdateMinSatke}
        // disableEscapeKeyDown={isUpdateMinSatke}
      >
        <DialogContent>
          <Box
            style={{
              background: "#FF632C",
              color: "#fff",
              padding: "25px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography variant="h6" style={{ color: "#fff" }}>
              Set User Details
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={1}>
                <label
                  style={{
                    color: "#fff",
                    paddingTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  Project Token Contribution %
                </label>
                <TextField
                  // style={{ border: "1px solid #fff" }}
                  variant="outlined"
                  id="outlined-basic"
                  placeholder=""
                  fullWidth
                  type="number"
                  value={adminFee}
                  onChange={(e) => setAdminFee(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={1}>
                <label
                  style={{
                    color: "#fff",
                    paddingTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  Contribution Token fee %
                </label>
                <TextField
                  // style={{ border: "1px solid #fff" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  type="number"
                  value={userFee}
                  onChange={(e) => setUserFee(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={1}>
                <label
                  style={{
                    color: "#fff",
                    paddingTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  Max. Hard cap
                </label>
                <TextField
                  // style={{ border: "1px solid #fff" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  type="number"
                  value={maxHardCap}
                  onChange={(e) => setMaxHardCap(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
              disabled={isUpdatingFee}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={setFeehandler}
              disabled={adminFee === "" || userFee === "" || isUpdatingFee}
            >
              {feeBtnName} {isUpdatingFee && <ButtonCircularProgress />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
