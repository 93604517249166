import { Contract } from '@ethersproject/contracts'
import { RPC_URL_BNB } from 'src/constants'
import Web3 from 'web3'
export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`
  return sortAdd
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account))
}
export const getWeb3Provider = async (rpcUrls = RPC_URL_BNB) => {
  const httpProvider = new Web3.providers.HttpProvider(rpcUrls)
  return httpProvider
}

export const getWeb3Obj = async (rpcUrls = RPC_URL_BNB) => {
  const httpProvider = new Web3.providers.HttpProvider(rpcUrls)
  const web3 = await new Web3(httpProvider)
  return web3
}

export const getWeb3ContractObject = async (
  abi,
  contractAddress,
  rpcUrls = RPC_URL_BNB,
) => {
  const web3 = await getWeb3Obj(rpcUrls)
  const contract = await new web3.eth.Contract(abi, contractAddress)
  return contract
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  } else {
    return false
  }
}
