import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { getContract } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import FactoryABI from "src/constants/FactoryABI.json";
import { factoryAddress } from "src/constants";
import WhiteListAddress from "./WhiteListAddress";
export default function ModalSecondary({
  setUpdateMinSatkeOpen1,
  listingAddresses,
  isLoadingAddress,
  removeFromWhitelistHandler,
  isRemoving,
}) {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  User
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: "scroll", maxHeight: "30px" }}>
            {listingAddresses &&
              listingAddresses.length > 0 &&
              listingAddresses.map((data, i) => {
                return (
                  <WhiteListAddress
                    data={data}
                    index={i}
                    removeFromWhitelistHandlers={(data) =>
                      removeFromWhitelistHandler(data)
                    }
                    isRemoving={isRemoving}
                    setUpdateMinSatkeOpen1={(item) =>
                      setUpdateMinSatkeOpen1(item)
                    }
                  />
                );
              })}
            {isLoadingAddress && (
              <Box display="flex" alignItems="center ">
                <Typography variant="h4">Loading...</Typography>
                <ButtonCircularProgress />
              </Box>
            )}
            {!isLoadingAddress && listingAddresses.length === 0 && (
              <Typography variant="h4">
                No whitlisted addresses found!!
              </Typography>
            )}
            {isRemoving && (
              <Box display="flex" alignItems="center " width="100%" p={1}>
                <Typography variant="h4" style={{ color: "#fff" }}>
                  Removing...
                </Typography>
                <ButtonCircularProgress />
              </Box>
            )}
          </TableBody>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            disabled={isRemoving}
            onClick={() => setUpdateMinSatkeOpen1(false)}
          >
            Close
          </Button>
        </Table>
      </TableContainer>
    </div>
  );
}
