import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import FactoryABI from "src/constants/FactoryABI.json";
import InfoABI from "src/constants/InfoABI.json";
import PresaleABI from "src/constants/PresaleABI.json";
import { getWeb3ContractObject } from "src/utils";
import { factoryAddress, ACTIVE_NETWORK } from "src/constants";
import Web3 from "web3";
import apiConfig from "src/ApiConfg/ApiConfig";
import moment from "moment";
import { toast } from "react-toastify";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const web3 = new Web3();
  const { activate, account, deactivate, chainId } = useWeb3React();
  const [allPoolList, setAllPoolList] = useState([]);
  const [pastPoolList, setPastPollsList] = useState([]);
  const [upcommingPoolist, setUpcommingPoolList] = useState([]);
  const [isLoading, setisloading] = useState(false);
  const [totall, setTotal] = useState("");
  const [fundedProjectList, setFundedProjectList] = useState([]);
  const [totalFundedValue, setTotalFunded] = useState("");
  const [totalInvestors, setTotalInvestors] = useState("");
  const [adminAccount, setAdmin] = useState("");
  const [onGoingPoolList, setOnGoingPoolList] = useState([]);
  const getPoolAllDatahandler = async () => {
    try {
      setisloading(true);
      const contractObj = await getWeb3ContractObject(
        FactoryABI,
        factoryAddress
      );
      const wittyIDOCALL = await contractObj.methods.WITTYIDO().call();
      const wittyContractOnj = await getWeb3ContractObject(
        InfoABI,
        wittyIDOCALL
      );
      const admin = await wittyContractOnj.methods.owner().call();
      setAdmin(admin);
      const res = await axios.get(apiConfig.presaleList);
      if (res.data.statusCode === 200) {
        console.log("resultPresale----", res.data);
        const list = res.data.result;
        var add = [];
        for (let i = 0; i < list.length; i++) {
          const listData = list[i];
          const presaleAddress = await wittyContractOnj.methods
            .getPresaleAddress(i)
            .call();

          const presalePoolDataObj = await getWeb3ContractObject(
            PresaleABI,
            presaleAddress
          );

          const calls = [
            {
              name: "saleTitle",
            },
            {
              name: "openTime",
            },
            {
              name: "hardCapInWei",
            },
            {
              name: "softCapInWei",
            },
            {
              name: "totalTokens",
            },
            {
              name: "totalCollectedWei",
            },
            {
              name: "tokensLeft",
            },
            {
              name: "presaleCancelled",
            },
            {
              name: "tokenPriceInWei",
            },
            {
              name: "token",
            },
            {
              name: "wittyIDOId",
            },
            {
              name: "closeTime",
            },
            {
              name: "ammLiquidityAdded",
            },
            {
              name: "ammLiquidityPercentageAllocation",
            },
            {
              name: "totalInvestorsCount",
            },
            {
              name: "investmentToken",
            },
            {
              name: "minInvestInWei",
            },
            {
              name: "linkDiscord",
            },
            {
              name: "linkTelegram",
            },
            {
              name: "linkTwitter",
            },
          ];
          let aboutData = {};

          for (let i = 0; i < calls.length; i++) {
            aboutData[calls[i].name] = await presalePoolDataObj.methods[
              calls[i].name
            ]().call();
          }

          let obj = {
            contractAddress: presaleAddress,
            presaleAddress: presaleAddress,
            uniLiquidityAdded: JSON.parse(aboutData.ammLiquidityAdded),
            uniLiquidityPercentageAllocation:
              aboutData.ammLiquidityPercentageAllocation.toString(),
            saleTitle: web3.utils.hexToAscii(aboutData.saleTitle),
            openTime: aboutData.openTime,
            closeTime: aboutData.closeTime,
            hardCapInWei: web3.utils.fromWei(aboutData.hardCapInWei, "ether"),
            softCapInWei: web3.utils.fromWei(aboutData.softCapInWei, "ether"),
            totalTokens: web3.utils.fromWei(aboutData.totalTokens, "ether"),
            totalCollectedWei: web3.utils.fromWei(
              aboutData.totalCollectedWei,
              "ether"
            ),
            tokensLeft: web3.utils.fromWei(aboutData.tokensLeft, "ether"),
            saleLive: aboutData.totalTokens - aboutData.tokensLeft,
            presaleCancelled: aboutData.presaleCancelled,

            tokenPriceInWei: web3.utils.fromWei(
              aboutData.tokenPriceInWei,
              "ether"
            ),
            token: aboutData.token,
            wittyIDOId: aboutData.wittyIDOId,
            investmentToken: aboutData.investmentToken,
            minInvestInWei: aboutData.minInvestInWei,
            linkDiscord: web3.utils.hexToAscii(aboutData.linkDiscord),
            linkTelegram: web3.utils.hexToAscii(aboutData.linkTelegram),
            linkTwitter: web3.utils.hexToAscii(aboutData.linkTwitter),
            totalInvestorsCount: aboutData.totalInvestorsCount,
            presaleId: listData?._id,
            title: listData?.title,
            likesCount: listData?.likesCount,
            likesUsers: listData?.likesUsers,
            description: listData?.description,
          };

          add.push(obj);
          setAllPoolList((prev) => [...prev, obj]);
        }
      }
      // const adminAccount = await wittyContractOnj.methods
      //   .getPresalesCount()
      //   .call();

      setisloading(false);
    } catch (err) {
      console.log(err);
      setisloading(false);
    }
  };
  const connectToWallet = (data) => {
    const connector = injected;
    window.sessionStorage.removeItem("walletName");
    window.sessionStorage.setItem("walletName", "METAMASK");
    // setErrorMsg("");
    // setSuccessMSG("");
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log("error", error.message);
        // setErrorMsg(error.message + " Please install " + data.name);
        activate(connector);
        // setIsLoading(false);
        // setErrorPop(true);
      }
    });
  };
  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.statusCode === 200) {
        // getProfileHandler(res.data.result.token);
        setSession(res.data.result.token);
      } else {
        deactivate();
        setIsLogin(false);
        // setUserData();
      }
    } catch (error) {
      deactivate();
      console.log("ERROR", error);
    }
  };
  //function to check wheather the existing network is same as the application enviornment or not
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  //function to change te existing network to make it same as the application
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        // params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
      // setUserData();
    }
  }, [account]);
  useEffect(() => {
    if (allPoolList && allPoolList.length > 0) {
      let result = allPoolList;
      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return (
          data.openTime * 1000 < currentTimeStamp &&
          data.closeTime * 1000 > currentTimeStamp
        );
      });

      setUpcommingPoolList(filterResult);
    }
  }, [allPoolList]);

  useEffect(() => {
    if (allPoolList && allPoolList.length > 0) {
      let result = allPoolList;
      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.openTime * 1000 > currentTimeStamp;
      });

      setOnGoingPoolList(filterResult);
    }
  }, [allPoolList]);

  useEffect(() => {
    if (allPoolList && allPoolList.length > 0) {
      let result = allPoolList;
      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.closeTime * 1000 < currentTimeStamp;
      });

      setPastPollsList(filterResult);
    }
  }, [allPoolList]);
  useEffect(() => {
    let result = pastPoolList;

    let filterResult = result.filter((data) => {
      return data?.totalCollectedWei > "0";
    });
    setFundedProjectList(filterResult);
  }, [pastPoolList]);

  useEffect(() => {
    getPoolAllDatahandler();
  }, []);

  const disconnectWallte = async () => {
    deactivate();
  };

  useEffect(() => {
    if (fundedProjectList) {
      const authList = fundedProjectList?.map((data, i) => {
        return parseFloat(data.totalCollectedWei);
      });
      const total = authList.reduce(
        (prevValue, currentValue, index) => prevValue + currentValue,
        0
      );
      const list = fundedProjectList?.map((data, i) => {
        return parseFloat(data.totalInvestorsCount);
      });
      const totalInvestors = list.reduce(
        (prevValue, currentValue, index) => prevValue + currentValue,
        0
      );

      setTotalInvestors(totalInvestors);
      setTotalFunded(total);
    }
  }, [fundedProjectList]);
  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      connectToWallet();
    }
  }, []);
  useEffect(() => {
    if (allPoolList) {
      const authList = allPoolList?.map((data, i) => {
        return parseFloat(data.totalCollectedWei);
      });
      const total = authList.reduce(
        (prevValue, currentValue, index) => prevValue + currentValue,
        0
      );
      setTotal(total);
    }
  }, [allPoolList]);
  let data = {
    adminAccount,
    userLoggedIn: isLogin,
    userData,
    pastPoolList,
    upcommingPoolist,
    isLoading,
    allPoolList,
    totall,
    fundedProjectList,
    totalFundedValue,
    totalInvestors,
    onGoingPoolList,
    disconnectWallet: () => disconnectWallte(),
    connectWallet: () => connectToWallet(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    getPoolAllDatahandler: () => getPoolAllDatahandler(),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
