// let baseurl = "https://node-opensea-v2.mobiloitte.com";
let baseurl = "https://node-witty.mobiloitte.com";

let user = `${baseurl}/api/v1/user`;

const apiConfig = {
  //USER
  likeDislikePresale: `${user}/likeDislikePresale/`,
  connectWallet: `${user}/connectWallet`,
  addPresale: `${user}/addPresale`,
  presaleList: `${user}/presaleList`,
};
export default apiConfig;
